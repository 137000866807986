import { createContext, useContext, useState } from 'react';

const RegistrationContext = createContext();

export const useRegistration = () => useContext(RegistrationContext);

export const RegistrationProvider = ({ children }) => {
  const [registrationInProgress, setRegistrationInProgress] = useState(false);

  return (
    <RegistrationContext.Provider value={{ registrationInProgress, setRegistrationInProgress }}>
      {children}
    </RegistrationContext.Provider>
  );
};
