// OrgWrapper.js
import React from 'react';
import OrgProvider from './OrgProvider';

const OrgWrapper = ({ children }) => {
  return (
    <OrgProvider>
      {children}
    </OrgProvider>
  );
};

export default OrgWrapper;
